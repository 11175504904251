@import '@packhelp/platform-dsl/theme/_theme.scss';

.two-column-layout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 157px auto;

  @media only screen and (min-width: 815px) {
    grid-template-columns: auto 640px;
  }

  @media only screen and (min-width: 1280px) {
    grid-template-columns: 50% 50%;
  }
}

.first-column {
  background-color: $additional-mariner;
  padding: $spacing-l $spacing-s;
}

.second-column {
  display: grid;
  grid-template: 157px auto / 1fr 4fr 1fr;

  > .content {
    grid-row: 2 / 2;
    grid-column: 2 / 3;
  }
}
