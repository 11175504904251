@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;
@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;

.table {
  margin: 0;
  padding: 0;

  li {
    display: flex;
    margin: 0;
    padding: spacings.$spacing-xs 0;
    justify-content: space-between;


    &:not(:last-child) {
      border-bottom: 1px solid colors.$general-athens-gray;
    }

    span:first-child {
      color: colors.$general-gray-chateau;
    }

    span:last-child {
      color: colors.$general-small-stone;
    }
  }

  &__link {
    color: colors.$general-small-stone;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;

    svg {
      margin-left: spacings.$spacing-xxs;
      fill: colors.$general-small-stone;
    }
  }
}
