@import '@packhelp/platform-dsl/theme/_theme.scss';

$spacings: (
  xxs: $spacing-xxs,
  xs: $spacing-xs,
  s: $spacing-s,
  m: $spacing-m,
  l: $spacing-l,
  xl: $spacing-xl,
  xxl: $spacing-xxl
);

.heading {
  margin: 0;
  padding: 0;
  color: $general-small-stone;
  &--h1 {
    @extend .typography--24-24-medium;
    margin-bottom: $spacing-s;
  }
  &--h2 {
    @extend .typography--16-24-medium;
    margin-bottom: $spacing-xs;
  }
  &--h3 {
    @extend .typography--14-20-medium;
    margin-bottom: $spacing-xs;
  }
  &--h4 {
    @extend .typography--16-24;
    margin-bottom: $spacing-xs;
  }

  @each $size, $value in $spacings {
    &--mb-#{$size} {
      margin-bottom: $value !important;
    }
  }
}
