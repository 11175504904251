@import '@packhelp/platform-dsl/theme/_theme.scss';

.param {
  margin-bottom: $spacing-s;
  display: block;
  @extend .typography--14-16;
  color: $general-small-stone;

  label {
    display: block;
    @extend .typography--12-16;
    color: $general-gray-chateau;
    margin-bottom: $spacing-xxs;
  }

  a {
    text-decoration: underline;
    color: $additional-lightish-blue;
  }
}
