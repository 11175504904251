@import '@packhelp/platform-dsl/theme/_theme.scss';

.diecut-preview {
  flex-shrink: 0;
  flex-grow: 0;
  width: 48px;
  height: 48px;
  margin-right: $spacing-s;
  display: flex;
  align-items: center;
  justify-content: center;

  img,
  svg {
    max-height: 100%;
    max-width: 100%;
  }

  img {
    border: 1px dashed $general-iron;
  }

  svg * {
    vector-effect: non-scaling-stroke;
  }
}

.diecut-label {
  color: $additional-lightish-blue;
  text-decoration: underline;
}

.download-file {
  svg path {
    fill: $additional-lightish-blue;
  }
}
