@import '@packhelp/platform-dsl/theme/_theme.scss';

.product-tile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: $spacing-s;
  background-color: $general-white;
  border: 1px solid $general-iron;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &__svg-wrapper {
    width: 100%;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $spacing-s;
    svg {
      flex: 1 1 auto;
      max-width: calc(100% - #{$spacing-s});
      max-height: calc(100% - #{$spacing-s});
      line[stroke-width='0.2'],
      path[stroke-width='0.2'] {
        stroke-width: 0.02em;
      }
    }
  }
  &__spacer {
    content: '';
    display: block;
    flex: 1;
  }

  &__name {
    @extend .typography--14-16;
    color: $general-small-stone;
  }

  &__cta {
    @extend .typography--12-16;
    text-decoration: underline;
    color: $additional-lightish-blue;
  }
}
