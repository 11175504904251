@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;
@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;
@use '@packhelp/platform-dsl/theme/_typography.scss' as typography;

.root {
  max-width: 294px;
  width: 100%;

  .cardTitle {
    @extend .typography--14-20-medium;
    display: block;
    padding-bottom: spacings.$spacing-s;
    margin-bottom: spacings.$spacing-s;
    border-bottom: colors.$general-athens-gray 1px solid;
  }

  .form {
    padding-bottom: spacings.$spacing-s;
    margin-bottom: spacings.$spacing-s;
    border-bottom: colors.$general-athens-gray 1px solid;
  }

  .input {
    margin-top: spacings.$spacing-s;
    width: 100%;

    .label {
      display: inline-block;
      color: colors.$general-small-stone;
      margin-bottom: spacings.$spacing-xxs;
      @extend .typography--14-16;
    }
  }

  .preview {
    display: grid;

    &__loading {
      display: flex;
      justify-content: space-between;

      svg {
        width: 100%;
      }
    }

    &__group_header {
      color: colors.$general-deep-gray;
      margin-bottom: spacings.$spacing-s;
      @extend .typography--14-20-medium;
    }

    &__attribute_label {
      color: colors.$general-gray-chateau;
    }

    &__group_value {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: spacings.$spacing-xs;
      @extend .typography--14-16;

      &--text {
        grid-column: 1 / span 2;
        padding-top: spacings.$spacing-xs;
        color: colors.$general-small-stone;
      }

      &--url {
        grid-column: 2 / span 1;
        text-decoration: underline;
        color: colors.$additional-lightish-blue;
        padding-top: spacings.$spacing-xs;
        &:first-of-type {
          padding-top: 0;
        }
      }
    }

    &__group_value:last-of-type {
      border-bottom: none;
    }

    &__group_value:not(:last-of-type) {
      padding-bottom: spacings.$spacing-xs;
      margin-bottom: spacings.$spacing-xs;
      border-bottom: colors.$general-athens-gray 1px solid;
    }
  }
}
