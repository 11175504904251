@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;

.table {
  margin: 0;
  padding: 0;

  li {
    display: flex;
    margin: 0;
    justify-content: space-between;

    span:first-child {
      color: colors.$general-gray-chateau;
    }

    span:last-child {
      color: colors.$general-small-stone;
    }
  }
}

.note {
  color: colors.$general-gray-chateau;
}