@import '@packhelp/platform-dsl/theme/_theme.scss';

.link {
  padding: 0 $spacing-s;
  cursor: pointer;
  opacity: 0.5;

  &--active,
  &:hover {
    opacity: 1;
    text-decoration: none;
  }
  &--active {
    font-weight: $font-weight-bold;
  }
}
