@import '@packhelp/platform-dsl/theme/_global.scss';

html,
body {
  height: 100%;

  margin: 0;
  padding: 0;

  transform: translate3d(0, 0, 0); // safari GPU acceleration
}

#root {
  height: 100%;
  overflow-y: auto;
}
