@import '@packhelp/platform-dsl/theme/_theme.scss';

.diecut-tile {
  margin-bottom: $spacing-s;
  padding-left: $spacing-m;

  &__full-width {
    padding-left: 0;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $spacing-xs;
  }

  &__thumbnail {
    max-width: 100%;
    text-align: center;

    img,
    svg {
      max-width: 100%;
      border: 1px dashed $general-iron;
      max-height: 200px;
    }
    svg * {
      vector-effect: non-scaling-stroke;
    }
  }

  &__dieline-params {
    display: flex;
    flex-direction: column;
  }
}

.download-pdf {
  align-self: flex-end;
  justify-self: flex-end;
  margin: auto 0 0 0;
}

.invalid-knife-length {
  color: $additional-pale-red;
  display: flex;
  flex-direction: column;

  span {
    @extend .typography--12-16;
    margin-top: $spacing-xs;
  }
}

.maximum-number-of-elements {
  @extend .typography--12-16;
  margin-top: $spacing-xs;
}
