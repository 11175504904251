@import '@packhelp/platform-dsl/theme/_theme.scss';

.wrapper {
  display: flex;
  justify-content: stretch;

  .select-code {
    flex-grow: 0;
    min-width: 100px;
    margin-right: -1px;

    &:hover,
    &.open {
      z-index: 1;
    }

    > div {
      border-radius: 3px 0 0 3px;
    }
  }

  .input-number {
    flex-grow: 1;

    > div > input {
      border-radius: 0 3px 3px 0;
    }
  }
}

.code-label {
  display: flex;

  svg {
    filter: drop-shadow(0 0 1px $general-iron);
    margin-right: $spacing-xs;
  }
}
