@import '@packhelp/platform-dsl/theme/_theme.scss';

.dieline-template {
  padding-left: $spacing-m;
  flex: 1;

  svg {
    width: 100%;
    height: auto;
    max-height: 400px;
    max-width: 600px;
  }
}
