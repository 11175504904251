@import '@packhelp/platform-dsl/theme/_theme.scss';

.sidebar {
  flex: 0 1 224px;
  overflow-y: auto;
  &--left {
    border-right: 1px solid $general-iron;
  }
  &--right {
    border-right: 1px solid $general-iron;
  }
  &--padding {
    &-extra-small {
      padding: $spacing-xs;
    }
    &-small {
      padding: $spacing-s;
    }
    &-medium {
      padding: $spacing-m;
    }
    &-large {
      padding: $spacing-l;
    }
    &-extra-large {
      padding: $spacing-xl;
    }
  }
}
