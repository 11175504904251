@import '@packhelp/platform-dsl/theme/_theme.scss';

.content {
  flex: 1;
  overflow-y: auto;

  &--padding {
    &-small {
      padding: $spacing-s;
    }
    &-medium {
      padding: $spacing-m;
    }
    &-large {
      padding: $spacing-l;
    }
  }
}
