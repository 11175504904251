@import '@packhelp/platform-dsl/theme/_theme.scss';

.user-name {
  @extend .typography--16-22;
}

.user-email {
  @extend .typography--10-16;
  color: $general-gray-chateau;
}
