@import '@packhelp/platform-dsl/theme/_theme.scss';

.dieline {
  width: 100%;
  padding: 0 $spacing-s;
  margin-top: -$spacing-xl;

  &__content {
    display: flex;
  }
  &__svg {
    padding: $spacing-s $spacing-xl $spacing-s $spacing-s;
    flex: 1;

    svg {
      max-height: 400px;
      * {
        vector-effect: non-scaling-stroke;
      }
    }
  }
}
