@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;
@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;

.root {
  flex: 1;
  border: 1px solid colors.$general-iron;

  .header {
    background: colors.$general-light-chateau;
    padding: spacings.$spacing-xs;
  }

  .content {
    padding: spacings.$spacing-xs;

    &__table {
      margin: 0;
      padding: 0;

      li {
        display: flex;
        margin: 0;
        justify-content: space-between;

        span:first-child {
          color: colors.$general-gray-chateau;
        }

        span:last-child {
          color: colors.$general-small-stone;
        }
      }
    }
  }
}