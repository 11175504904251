@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;
@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;

$border-color: #ccd7ff;
$available-border-color: #d3eec8;

.availability-box {
  margin-top: spacings.$spacing-m;
  border: 1px solid $border-color;
  border-radius: spacings.$spacing-xxs;
  padding: spacings.$spacing-s;
  position: sticky;
  top: spacings.$spacing-m;
  background: colors.$additional-zircon;

  & > svg {
    display: block;
    margin: 0 auto spacings.$spacing-xs auto;
  }

  &.available {
    background: colors.$additional-tusk-light;
    border-color: $available-border-color;
  }

  &.loading {
    text-align: center;
    background: colors.$additional-zircon;
  }

  &.notAvailable {
    border-color: colors.$additional-dairy-cream;
    background: colors.$additional-early-dawn;
  }
}
