@import '@packhelp/platform-dsl/theme/_theme.scss';

.separator {
  margin: 0;
  margin-top: $spacing-s;
  height: 0;
  width: 100%;
  &--with-line {
    margin-bottom: $spacing-s;
    border-top: 1px solid $general-iron;
  }
}
