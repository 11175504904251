@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;
@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;

.item {
  padding: spacings.$spacing-s 0;
  border-bottom: 1px solid colors.$general-iron;

  &.collapsed {
    border-bottom: none;
  }
}
