@import '@packhelp/platform-dsl/theme/_theme.scss';

.diecut-placeholder {
  position: relative;
  &__info {
    color: $general-small-stone;
    @extend .typography--16-24-medium;

    &-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }
}

.diecut-tile {
  padding-left: $spacing-m;

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $spacing-xs;
  }

  &__diecut-layout,
  &__dieline-params {
    filter: blur(3px);
    opacity: 0.7;
  }
  &__thumbnail {
    filter: blur(1.5px);
    svg {
      max-width: 100%;
      max-height: 200px;
    }
  }
}
