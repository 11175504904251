@import '@packhelp/platform-dsl/theme/_theme.scss';

.navigation-menu-link {
  display: block;
  font-size: $font-size-14;
  line-height: $line-height-16;
  color: $general-small-stone;
  font-weight: $font-weight-normal;
  padding: $spacing-xs;
  margin: 0;
  cursor: pointer;
  &:hover,
  &--active {
    font-weight: $font-weight-bold;
    text-decoration: none;
  }
  &--active {
    background-color: $general-athens-gray;
  }
}

[role='navigation'] {
  & > [role='navigation'] {
    .navigation-menu-link {
      padding-left: $spacing-s;
    }
    & > [role='navigation'] {
      .navigation-menu-link {
        padding-left: $spacing-m;
      }
      & > [role='navigation'] {
        .navigation-menu-link {
          padding-left: $spacing-l;
        }
        & > [role='navigation'] {
          .navigation-menu-link {
            padding-left: $spacing-xl;
          }
        }
      }
    }
  }
}
