@import '@packhelp/platform-dsl/theme/_theme.scss';

.settings-form {
  max-width: 608px;
  margin: auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-s;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $spacing-s;
  }
}
