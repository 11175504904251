.instant_price {
  width: 100%;

  &__content {
    display: flex;
  }

  &__leftbox {
    max-width: 294px;
    width: 100%;
  }
}