@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;
@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;

.quantityContainer {
  margin-bottom: spacings.$spacing-m;

  .header {
    display: flex;
    align-items: center;
    gap: 4px;

    &__label {
      color: colors.$general-gray-chateau;
    }
  }

  .suppliers {
    margin: spacings.$spacing-xs 0;
  }

  .prices {
    &__toggleLink {
      display: block;
      color: colors.$additional-lightish-blue;
      cursor: pointer;
      user-select: none;
      margin-top: spacings.$spacing-s;
    }
  }
}
