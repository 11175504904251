@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;
@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;

.header {
  display: flex;

  &__item {
    flex: 1 1 25%;
    align-self: center;

    &--label {
      color: colors.$general-gray-chateau;
    }

    span {
      display: block;
      width: 100%;
    }

    &--action {
      text-align: right;
      display: flex;
      align-items: center;
    }

    &--link {
      color: colors.$additional-lightish-blue;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;

      svg {
        margin-left: 12px;
        fill: colors.$additional-lightish-blue;
      }
    }
  }
}