.string-to-svg {
  display: contents;

  svg {
    width: 100%;
    height: auto;
    line[stroke-width='0.2'],
    path[stroke-width='0.2'] {
      stroke-width: 0.02em;
    }
  }
}
