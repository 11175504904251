@import '@packhelp/platform-dsl/theme/_theme.scss';

$dimensions: 'depth', 'width', 'height', 'length';

.svg-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.product-template {
  &--hide-dimensions {
    [id] {
      display: none;
    }
  }
  text {
    stroke-width: 0;
  }
  @each $dimension in $dimensions {
    &--highlight-#{$dimension} {
      [id] {
        fill: $general-gray-chateau;
        stroke: $general-gray-chateau;
        & > * {
          fill: $general-gray-chateau;
          stroke: $general-gray-chateau;
        }
      }
      [id='#{$dimension}'] {
        fill: $additional-mariner;
        stroke: $additional-mariner;
        & > * {
          fill: $additional-mariner;
          stroke: $additional-mariner;
        }
      }
    }
  }
}
