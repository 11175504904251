@import '@packhelp/platform-dsl/theme/_theme.scss';

.navigation-menu-header {
  display: block;
  font-size: $font-size-12;
  line-height: $line-height-16;
  color: $general-gray-chateau;
  font-weight: $font-weight-normal;
  padding: $spacing-xs;
  margin: 0;
}

[role='navigation'] {
  & > [role='navigation'] {
    .navigation-menu-header {
      padding-left: $spacing-s;
    }
    & > [role='navigation'] {
      .navigation-menu-header {
        padding-left: $spacing-m;
      }
      & > [role='navigation'] {
        .navigation-menu-header {
          padding-left: $spacing-l;
        }
        & > [role='navigation'] {
          .navigation-menu-header {
            padding-left: $spacing-xl;
          }
        }
      }
    }
  }
}
