@import '@packhelp/platform-dsl/theme/_theme.scss';

.wrapper {
  margin: 0 auto;
  &--medium {
    max-width: 816px;
  }
  &--big {
    max-width: 1200px;
  }
}
