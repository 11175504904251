@import '@packhelp/platform-dsl/theme/_theme.scss';

.product-templates-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: $spacing-s;
  row-gap: $spacing-s;
  &--loading {
    padding: $spacing-xl;
    text-align: center;
  }
}
