@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;
@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;

.warnings {
  margin-bottom: spacings.$spacing-s;
}

.box {
  background: colors.$additional-early-dawn;
  border: 1px solid rgba(255, 248, 234, 0.2);
  border-radius: 4px;
  padding: spacings.$spacing-xs spacings.$spacing-s;
  margin-top: spacings.$spacing-m;
  display: flex;
  align-items: center;

  svg {
    fill: colors.$additional-squash;
    margin-right: spacings.$spacing-s;
  }

  span {
    color: colors.$general-small-stone;
  }
}