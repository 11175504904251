@import '@packhelp/platform-dsl/theme/_theme.scss';

.form-field {
  margin-bottom: $spacing-s;

  label {
    display: block;
    @extend .typography--14-20;
    color: $general-nevada;
    margin-bottom: $spacing-xxs;
  }
}
