@import '@packhelp/platform-dsl/theme/_theme.scss';

.cell-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  white-space: normal;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-s;

  > div > div {
    width: auto;

    > div {
      background: transparent;
      padding: 0;
    }
  }
}
