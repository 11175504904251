@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;
@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;
@use '@packhelp/platform-dsl/theme/_typography.scss' as typography;


:global {
  @import '@packhelp/platform-pss-form/platform-pss-form';
}

.root {
  flex: 1;
  padding-left: spacings.$spacing-s;

  .cardTitle {
    @extend .typography--14-20-medium;
    display: block;
    padding-bottom: spacings.$spacing-s;
    border-bottom: colors.$general-athens-gray 1px solid;
  }

  .content {
    form {
      padding: 0;
    }
  }

  .divider {
    border: none;
    height: 1px;
    color: colors.$general-iron;
    background-color: colors.$general-iron;
    margin: spacings.$spacing-s 0;
  }
}
