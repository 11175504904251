@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;
@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;

.buttonLoader {
  padding-left: 5px;
}

.label {
  display: block;
  padding-bottom: spacings.$spacing-xs;
  color: colors.$general-small-stone;
}

.paragraph {
  display: block;
  color: colors.$general-small-stone;
  margin-bottom: spacings.$spacing-m;
}
