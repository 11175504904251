@import '@packhelp/platform-dsl/theme/_theme.scss';

.input-wrapper {
  position: relative;

  span {
    position: absolute;
    right: 1px;
    top: 1px;
    border-left: 1px solid $general-iron;
    padding: $spacing-xxs + 1px;
    background: $general-white;
  }
}
