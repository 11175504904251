@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;
@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;

.root {
  flex: 1;
  border: 1px solid colors.$general-iron;

  .header {
    background: colors.$general-light-chateau;
    padding: spacings.$spacing-xs;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__switch {
      div>button {
        font-size: 12px;
        line-height: 13px;
        height: auto;
        padding: 2px spacings.$spacing-xs;
        min-width: auto;
      }
    }
  }

  .content {
    padding: spacings.$spacing-xs;
  }
}