@import '@packhelp/platform-dsl/theme/_theme.scss';

.dieline-preview {
  svg {
    width: 48px;
    height: 48px;
    margin-right: $spacing-s;
    * {
      vector-effect: non-scaling-stroke;
    }
  }
}

.dieline-label {
  color: $additional-lightish-blue;
  text-decoration: underline;
}

.download-file {
  svg path {
    fill: $additional-lightish-blue;
  }
}
