@use '@packhelp/platform-dsl/theme/_spacings.scss' as spacings;
@use '@packhelp/platform-dsl/theme/_colors.scss' as colors;

.root {
  flex: 2;
  border-top: 1px solid colors.$general-iron;
  border-bottom: 1px solid colors.$general-iron;

  .header {
    background: colors.$general-light-chateau;
    padding: spacings.$spacing-xs;
  }

  .content {
    padding: spacings.$spacing-xs;

    &__table {
      margin: 0;
      padding: 0;

      li {
        display: flex;
        margin: 0;
        justify-content: space-between;

        span:first-child {
          color: colors.$general-gray-chateau;
        }

        span:last-child {
          color: colors.$general-small-stone;
        }
      }
    }

    .collapsingTable {
      margin-top: spacings.$spacing-xs;

      &__trigger {
        color: colors.$general-small-stone;
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;

        svg {
          margin-left: spacings.$spacing-xs;
          fill: colors.$general-small-stone;
        }
      }
    }
  }
}

.table {
  margin-top: spacings.$spacing-xs;

  &__row {
    display: flex;
    border-bottom: 1px solid colors.$general-athens-gray;

    div {
      &:nth-child(1) {
        flex: 2
      }

      &:nth-child(n+2) {
        flex: 1
      }
    }

    &--header {
      color: colors.$general-gray-chateau;
    }

    &--cell {
      padding: spacings.$spacing-xxs 0;
      color: colors.$general-small-stone;
    }
  }
}